import { render, staticRenderFns } from "./operatePush.vue?vue&type=template&id=16c89ec6&scoped=true"
import script from "./operatePush.vue?vue&type=script&lang=js"
export * from "./operatePush.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c89ec6",
  null
  
)

export default component.exports