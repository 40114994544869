<template>
  <div class="overflow-scroll">
    <div style="min-width: 1170px">
      <a-card>
        <div class="table-operator">
          <a-form layout="inline">
            <a-row class="feeEditLabelWidth" style="line-height: 3.5">
              <a-col :md="6" :sm="24">
                <a-form-item label="任务ID">
                  <a-input
                    v-model="queryParam.code"
                    @pressEnter="select"
                    style="width: 175px"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="消息分类">-->
              <!--                  <a-select-->
              <!--                    style="width: 240px"-->
              <!--                    v-model="queryParam.messageType"-->
              <!--                    placeholder="请选择"-->
              <!--                  >-->
              <!--                    <a-select-option v-for="item in pushType" :key="item.id">-->
              <!--                      {{ item.name }}-->
              <!--                    </a-select-option>-->
              <!--                  </a-select>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <a-col :md="6" :sm="24">
                <a-form-item label="推送端">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.clientType"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                    default-value="0"
                  >
                    <a-select-option
                      v-for="item in clientTypeList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="状态">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.status"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in pushStatus" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="(!advanced && 6) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="
                    (advanced && { float: 'right', overflow: 'hidden' }) || {}
                  "
                >
                  <!--                  <a-button type="primary" @click="select">查询</a-button>-->
                  <a-button
                    style="margin-left: 8px"
                    @click="() => ((queryParam = {}), this.init())"
                    >重置</a-button
                  >
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="table-operator" style="margin-top: -16px">
          <a-button
            v-if="this.newHasRight === true"
            type="primary"
            icon="plus"
            @click="operatePushNew"
            >新建</a-button
          >
        </div>
        <a-table
          rowKey="id"
          :pagination="pagination"
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 1100 }"
          @change="changePageSize"
        >
          <!--      <a slot="name" slot-scope="text" href="javascript:;">{{text}}</a>-->
          <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
          <span slot="customerType" slot-scope="text, record">
            {{ record.customerType == 1 ? "全部" : "指定用户" }}
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <a
              v-if="viewHasRight === true"
              v-show="record.status !== '待推送'"
              @click="handleDetail(record)"
              >查看&nbsp;&nbsp;</a
            >
            <!--        <a-divider type="vertical" />  |-->
            <a
              v-if="editHasRight === true"
              v-show="record.status == '待推送'"
              @click="operatePushEdit(record)"
              >编辑&nbsp;&nbsp;</a
            >
            <a
              v-if="editHasRight === true"
              v-show="record.status == '待推送'"
              @click="operatePushDelete(record)"
              >废弃&nbsp;&nbsp;</a
            >
            <a
              v-if="newHasRight === true"
              v-show="record.status !== '已推送'"
              @click="operatePushCopy(record)"
              >复制&nbsp;&nbsp;</a
            >
            <!--            <a @click="handleActive(record)">操作日志</a>-->
          </span>
        </a-table>
        <a-modal
          title="操作日志"
          width="700px"
          v-model="active"
          @ok="handleOk"
          :okButtonProps="{ props: { disabled: true } }"
          :cancelButtonProps="{ props: { disabled: true } }"
          footer
          @change="changePageActionSize"
        >
          <a-table
            rowKey="id"
            :pagination="paginationaction"
            :columns="actioncolumns"
            :dataSource="actiondata"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </a-table>
        </a-modal>
      </a-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Bus from "@/bus";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
  
import { mapGetters } from "vuex";
import { hasPermissions } from "../../utils/auth";
import { AdminActions } from "../../utils/actions";
export default {
  name: "operatePush",
  components: {},
  data() {
    return {
      showNew: false,
      active: false,
      advanced: false,
      thisDetail: "",
      queryParam: {
        messageType: undefined,
        clientType: undefined,
        code: "",
        status: undefined
      },
      paginationaction: defaultPagination(() => {}),
      pagination: defaultPagination(() => {}),
      data: [],
      columns: [
        {
          title: "任务ID",
          dataIndex: "code",
          key: "code",
          fixed: "left"
        },
        {
          title: "创建人",
          dataIndex: "creator",
          key: "creator"
        },
        {
          title: "任务名称",
          dataIndex: "taskName",
          key: "taskName"
        },
        // {
        //   title: "消息分类",
        //   dataIndex: "messageType",
        //   key: "messageType"
        // },
        {
          title: "推送端",
          dataIndex: "clientType",
          key: "clientType"
        },
        {
          title: "城市",
          dataIndex: "cityName",
          key: "cityName",
          width: 160
        },
        {
          title: "发送对象",
          dataIndex: "customerType",
          key: "customerType",
          scopedSlots: { customRender: "customerType" }
        },
        {
          title: "发送时间",
          dataIndex: "pushTimeLg",
          key: "pushTimeLg"
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status"
        },
        {
          title: "操作",
          // dataIndex: "action",
          scopedSlots: { customRender: "action" },
          key: "action",
          fixed: "right",
          width: 180
        }
      ],
      //操作日志
      actioncolumns: [
        {
          title: "操作人",
          dataIndex: "operator",
          key: "operator"
        },
        {
          title: "操作时间",
          dataIndex: "operationTime",
          key: "operationTime",
          width: "130px"
        },
        {
          title: "操作内容",
          dataIndex: "content",
          key: "content"
        }
      ],
      actionId: "",
      actiondata: [],
      //状态
      pushStatus: [
        {
          id: 1,
          name: "待推送"
        },
        {
          id: 2,
          name: "已推送"
        },
        {
          id: 3,
          name: "废弃"
        }
      ],
      //消息分类
      pushType: [
        {
          id: 1,
          name: "活动消息"
        },
        {
          id: 2,
          name: "账单消息"
        },
        {
          id: 3,
          name: "通知消息"
        }
      ],
      //推送客户端
      clientTypeList: [
        {
          id: 1,
          name: "客户端"
        },
        {
          id: 2,
          name: "运营端"
        },
        {
          id: 3,
          name: "全部"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    newHasRight() {
      return hasPermissions(
        [AdminActions.PUSH_CONFIG_CREATE],
        this.authenticatedUser.actions
      );
    },
    viewHasRight() {
      return hasPermissions(
        [AdminActions.PUSH_CONFIG_READ],
        this.authenticatedUser.actions
      );
    },
    editHasRight() {
      return hasPermissions(
        [AdminActions.PUSH_CONFIG_EDIT],
        this.authenticatedUser.actions
      );
    }
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  methods: {
    moment,
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/push/config/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.messageType = this.changeMessageType(item.messageType);
            item.status = this.changeStatus(item.status);
            item.clientType = this.changeClientType(item.clientType);
            // item.customerType = item.customerType==1 ? "全部": "指定用户";
            item.pushTimeLg = this.changeTimeStyle(item.pushTimeLg);
          });
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    changeMessageType(val) {
      switch (val) {
        case 1:
          return "活动消息";
        case 2:
          return "账单消息";
        case 3:
          return "通知消息";
        default:
          return "";
      }
    },
    changeStatus(val) {
      switch (val) {
        case 1:
          return "待推送";
        case 2:
          return "已推送";
        case 3:
          return "废弃";
        default:
          return "";
      }
    },
    changeClientType(val) {
      switch (val) {
        case 1:
          return "客户端";
        case 2:
          return "运营端";
        case 3:
          return "全部";
        default:
          return "";
      }
    },
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    select() {
      this.init();
    },
    handleDetail(val) {
      this.$router.push({
        path: "/operate/operatePush/operatePushDetail",
        query: { thisDetailId: val.id }
      });
    },
    operatePushNew(val) {
      this.$router.push({
        path: "/operate/operatePush/operatePushNew",
        query: {
          thisDetailId: val.id,
          thisDetailstatus: val.status,
          thisDetailcustomerType: val.customerType
        }
      });
    },
    operatePushEdit(val) {
      this.$router.push({
        path: "/operate/operatePush/operatePushNew",
        query: { thisDetailId: val }
      });
    },
    cancelPustNew() {
      this.init();
      this.thisDetail = "";
    },
    handleActive(val) {
      this.actionId = val;
      this.handleActivecha();
      this.active = true;
    },
    handleActivecha(current, pageSize) {
      axios({
        url:   this.$baseUrl + "push/config/listPushLog",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          pushId: this.actionId.id
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (item.operationTime) {
              item.operationTime = moment(item.operationTime).format(
                "YYYY-MM-DD"
              );
            }
          });
          this.actiondata = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.paginationaction = pagination;
        }
      });
    },
    changePageActionSize(val) {
      this.handleActivecha(val.current, val.pageSize);
    },
    operatePushDelete(record) {
      axios({
        url:   this.$baseUrl + "/push/config/delete",
        mathod: "GET",
        params: {
          pushConfigId: record.id
        }
      }).then(() => {
        console.log("success");
      });
      this.init();
    },
    operatePushCopy(val) {
      this.$router.push({
        path: "/operate/operatePush/operatePushNew",
        query: { thisDetail: val }
      });
    },
    handleOk() {
      this.$refs.table.refresh();
    }
  }
};
</script>

<style scoped></style>
